var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_vm._m(0),_c('br'),_c('br'),_c('img',{staticClass:"image vdale_logo",attrs:{"alt":"Villagedale Community  Association","src":require("./assets/vca_logo_dark.png")}}),_c('br'),_c('h1',[_vm._v("We're here to help.")]),_c('br'),_vm._m(1),_vm._m(2),_c('br'),_vm._m(3),_vm._m(4),_vm._m(5),_c('br'),_c('br'),_vm._m(6),_c('div',{staticClass:"info"},[_vm._m(7),_vm._m(8),_c('P',[_c('b',[_vm._v("Villagedale Community Association")]),_vm._v(" is a registered society in the Province of Nova Scotia in good standing. Registration date: Nov 1, 2022. Registration number: 4466832.")])],1),_c('br'),_c('br'),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vdale_header"},[_c('h1',[_vm._v("Welcome :)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v("For support or to contribute, please contact "),_c('a',{attrs:{"href":"mailto:support@villagedale.ca"}},[_vm._v("support@villagedale.ca")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v("For the most up-to-date information on food security, visit us on Facebook"),_c('br'),_c('a',{attrs:{"href":"https://www.facebook.com/groups/villagedale","target":"new"}},[_vm._v("https://www.facebook.com/groups/villagedale")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h3',[_vm._v("In the News:")]),_c('p',[_c('a',{attrs:{"href":"https://www.cbc.ca/player/play/video/9.4223955","target":"new"}},[_vm._v("Shelburne County food bank fears running out of money")]),_c('br'),_vm._v(" CBC Nova Scotia News, 2024-05-10, Duration 3:15"),_c('br'),_vm._v(" It's been a very busy time at the food bank ever since the wildfires ravaged the area last spring. The number of people it is supporting is on the rise. Paul Palmeter has the story.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_c('b',[_vm._v("Welcome to the Villagedale Community Association!")])]),_c('p',[_vm._v(" We are a registered, non-profit society based in Nova Scotia, dedicated to fostering a diverse, inclusive, and supportive community that upholds the dignity of all. Our Food Security initiative is designed to ensure equitable access to nutritious food for 200 to 300 individuals each week. This initiative is powered by strong partnerships with local businesses like Sobeys, NoFrills, Needs, Madd Batter Bakery, through Second Harvest Food Rescues, as well as through the generosity of our neighbors and a range of grants. ")]),_c('p',[_vm._v(" Our collaborative efforts create a sustainable network that addresses immediate food needs and promotes long-term community resilience. By bridging local producers, volunteers, and donors, we strive to eliminate barriers and establish a compassionate, welcoming environment for everyone. ")]),_c('p',[_vm._v(" Through partnerships and unwavering commitment to diversity and inclusion, we aim to build a better future by empowering individuals and families with the resources and support they need. Together, we can ensure that no one in our community goes without the essentials, making Villagedale and area a stronger, more compassionate place. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"donations"},[_c('div',{staticClass:"donations-options"},[_c('h2',[_vm._v("Make a one-time contribution to Food Security:")]),_c('div',{attrs:{"id":"donate-button-container"}},[_c('div',{attrs:{"id":"donate-button"}})]),_c('br'),_c('br'),_c('h2',[_vm._v("Become a $5 Monthly Contributor:")]),_c('div',[_c('div',{attrs:{"id":"paypal-button-container-P-6FN68383JW4009302MPSZTPA"}})]),_c('br'),_c('h2',[_vm._v("Become a $10 Monthly Contributor:")]),_c('div',[_c('div',{attrs:{"id":"paypal-button-container-P-91J85934Y1818935AMQYKNNA"}})]),_c('br'),_c('h2',[_vm._v("Become a $20 Monthly Contributor:")]),_c('div',[_c('div',{attrs:{"id":"paypal-button-container-P-8YY58841KH8820210MQYKOVQ"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/media/VCA_Food_Security_Program_Survey.pdf","target":"new"}},[_vm._v("Food Security Survey")]),_vm._v(" | "),_c('a',{attrs:{"href":"/media/VCA_Food_Security_Initiative_Privacy_Policy.pdf","target":"new"}},[_vm._v("Privacy Policy")]),_vm._v(" | "),_c('a',{attrs:{"href":"/media/VCA_Diversity_and_Inclusion_Policy.pdf","target":"new"}},[_vm._v("Diversity and Inclusion Policy")]),_vm._v(" | "),_c('a',{attrs:{"href":"/media/2023_AGM_Financials.zip"}},[_vm._v("2023 Financial Reports")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" On a volunteer and non-profit basis the objective/s of the society is/are: "),_c('ul',[_c('li',[_vm._v("To relieve poverty by providing food and other basic necessities of life to needy individuals and families in Nova Scotia.")]),_c('li',[_vm._v("To undertake activities ancillary and incidental to the attainment of the above charitable purpose.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Board of Directors")]),_vm._v(": Carla Nickerson, Jeff Nickerson, Chelsea Turner, Meredith Symonds, Jamie Symonds, Cameron Albright")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vdale_footer"},[_c('p',{staticStyle:{"margin":"auto"}},[_vm._v("© 2023 Villagedale Community Association"),_c('br'),_vm._v("Website by "),_c('a',{staticStyle:{"color":"white"},attrs:{"href":"https://topsailsoftware.ca","target":"blank"}},[_vm._v("Topsail Software Inc")])]),_c('p',{staticStyle:{"margin":"auto","font-size":"0.6em"}},[_vm._v("Last updated: 04/08/2023")])])
}]

export { render, staticRenderFns }